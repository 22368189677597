
.QuotePreview {
  display: flex;
  justify-content: center;

  .imageContainer {
    background: white;
    width: 1200px;
    height: 628px;
    display: flex;
    justify-content: center;
    align-items: center;

    .image {
      height: 80%;
    }

  }
}
