
.ProfilePreview {

  .container {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    background: white;
    overflow: hidden;

    .profileContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding-left: 24px;
      padding-right: 12px;

      .imageContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .image {
          border-color: lightgrey;
          border-width: 1px;
          border-style: solid;
        }
      }

      .username {
        margin-top: 8px;
        font-weight: 500;
        white-space: nowrap;
      }

      .displayName {
        margin-top: 4px;
        color: #353535;
        white-space: nowrap;
      }
    }

    .mediumsContainer {
      height: 70%;
      margin-top: auto;
      margin-bottom: auto;

      .mediums {
        height: 100%;
        display: flex;

        .image1 {
          height: 100%;
          margin-left: 16px;
        }
      }

    }
  }
}
