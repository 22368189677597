
.RatingPreview {

  .container {
    width: 600px;
    height: 314px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    background: white;
    overflow: hidden;

    .ratingContainer {
      width: 314px;
      height: 314px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      .leftContainer {
        padding-right: 16px;

        .ratingProgress {
          height: 100%;

          .ratingValue {
            font-size: 48px;
            color: black;
            font-weight: 500;
          }

          .outOf {
            font-size: 12px;
          }
        }
      }

      .rightContainer {
        flex: 1;

        .imageContainer {
          text-align: center;

          .image {
            height: auto;
            width: 162px;
            border-color: lightgrey;
            border-style: solid;
            border-width: 0.5px;

            //@media (min-width: $tablet-width) {
            //  height: auto;
            //  width: 236px;
            //}

          }
        }
      }
    }
  }
}
